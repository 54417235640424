import _isUndefined from 'lodash/isUndefined';
import _isEmpty from 'lodash/isEmpty';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import { api_version, getApiClient } from '../..';

/**
 * @param {*} string: {email: <email>, password: <password>}
 */
const createSession = async ({ email, password }) => {
  const response = await getApiClient().post(`/auth/session`, {
    data: {
      grant_type: 'password',
      email,
      password,
    },
  });
  return response;
};

const refreshSession = async () => {
  const token = getApiClient().getToken();
  const refresh_token = token ? token.refresh_token : '';

  const response = await getApiClient().post(`/auth/session`, {
    data: {
      grant_type: 'refresh_token',
      refresh_token,
    },
  });
  return response;
};

const connectProvider = async (provider, params) => {
  const validateProviders = {
  };

  const isValidProvider = () => {
    const providerData = validateProviders[provider];
    const paramValues = providerData ? _pick(params, providerData) : {};
    return providerData && _keys(paramValues).length === providerData.length;
  };

  if (!isValidProvider()) {
    throw new Error('provider is not valid or missing params');
  }

  const response = await getApiClient().post(`${api_version}integrations/${provider}`, {
    data: params,
  });
  return response;
};

/**
 * @param {*} string: {assertion: <access_token>, provider: <provider>}
 */
const createProviderSession = async ({ assertion, provider }) => {
  const response = await getApiClient().post(`/auth/session`, {
    data: {
      grant_type: 'assertion',
      assertion,
      provider,
    },
  });

  return response;
};

export const buildProviderUrl = (provider, source) => {
  const translateProviders = {
    facebook: 'facebook',
    google: 'google_oauth2',
    foursquare: 'foursquare',
    fitbit: 'fitbit',
    garmin: 'garmin',
    tripit: 'tripit',
  };

  const providerPath = translateProviders[provider];
  if (_isUndefined(providerPath)) {
    throw new Error(`provider=${provider} is not valid.`);
  }

  getApiClient().setCookie('provider_source', source);

  return `/api/v2/users/auth/${providerPath}?source=${source}`;
};

/**
 * @param {*} string: {provider: <provider>, code: <code>}
 */
const processProviderCallback = async (query, context) => {
  const {
    provider,
    code,
    state,
    geolocation,
    error,
    error_code,
    error_reason,
  } = query;
  

  const isError = !_isEmpty(error);

  const providerSource = getApiClient().getCookie('provider_source', context);
  getApiClient().delCookie('provider_source');

  const railsCookie = getApiClient().getCookie('_travelapp_session', context);
  const urlParams = { geolocation };

  if (code) urlParams['code'] = code;
  if (state) urlParams['state'] = state;

  const headers = {
    Cookie: `_travelapp_session=${railsCookie};`,
  };

  // eslint-disable-next-line no-console
  console.log('[processProviderCallback] headers -> ', headers);

  const createSession = () => getApiClient().get(`${api_version}users/auth/${provider}/callback`, { urlParams, headers })
  const destroySession = () => {
  // eslint-disable-next-line no-console
    console.log('Deleting authentication', provider, error, error_code, error_reason);
    return getApiClient().delete(`${api_version}users/authentications/${provider}`, { headers })
  }

  if (isError) {
    const shouldDestroySession = providerSource === 'ManualSync';
    const response = await shouldDestroySession ? destroySession() : Promise.resolve({ data: { source: providerSource, error } });
    return response;
  } else {
    return await createSession().catch((err) => {
      console.log("[ERROR] CREATE SESSION by PROVIDER CALLBACK", err);
      return {
        source: providerSource,
        error: 'Hey, Something went wrong, please try again',
      };
    });
  }
};

/**
 * @param {*} token
 */
const destroySession = async ({ token }) => {
  const response = await getApiClient().delete(`${api_version}oauth/token`, {
    data: {
      access_token: token,
    },
  });
  return response;
};

export default {
  createSession,
  refreshSession,
  createProviderSession,
  processProviderCallback,
  destroySession,
  connectProvider,
};
